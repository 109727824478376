export interface EnvType {
  REACT_APP_API_URL: string;
  REACT_APP_SOCKET_URL: string;
  REACT_APP_REGION: string;
  REACT_APP_VOXIMPLANT_ACCOUNT_NAME: string;
  REACT_APP_VOXIMPLANT_APPLICATION_NAME: string;
  REACT_APP_CLOUD_PAY: string;
  REACT_APP_VERSION: string;
  NODE_ENV?: string;
}

declare global {
  interface Window {
    localStream: MediaStream;
    env: Record<keyof EnvType, string>;
  }
}

export const env: Partial<EnvType> = {
  ...process.env,
  ...window.env,
};
