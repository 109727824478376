import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Radio } from 'antd';
import dayjs from 'dayjs';

import { configMonth } from '../../../1_shared/config/configMonth';
import { dayConfig } from '../../../1_shared/config/dayConfig';
import { SlotType } from '../../../1_shared/config/interfaces/SlotType';
import { RoutePath } from '../../../1_shared/config/routes';
import { getClosestSlots } from '../../../1_shared/lib/helpers/getClosestSlots';
import { Button, RadioButton, Typography } from '../../../1_shared/ui';
import { InfoSpecialist } from '../../../2_entities';

import { IPopupSlotsProps } from './interfaces/IPopupSlotsProps';

import 'app/styles/global/global.scss';
import './popup.scss';

dayjs.locale('ru');

const PopupSlots = ({
  open,
  slots,
  onClose,
  onChange,
  spec,
  value,
}: IPopupSlotsProps) => {
  const memoSlots = useMemo(() => getClosestSlots(slots), [slots]);
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onCancel={onClose}
      className="popup--time"
      width="100%"
      footer={
        value ? (
          <div className="footer-popup">
            <InfoSpecialist
              isPopover
              classname="infoSpec"
              specialist={spec}
              value={value}
            />
            <Button
              onClick={() => {
                navigate(RoutePath.ORDER, {
                  state: { id: spec.id, slot: value },
                });
                // @ts-ignore
                ym(97788822, 'reachGoal', 'booking');
              }}
            >
              ЗАБРОНИРОВАТЬ
            </Button>
          </div>
        ) : null
      }
    >
      <h1 className="popup__title">Ближайшие слоты</h1>
      <Radio.Group
        value={value?.slotId}
        buttonStyle="solid"
        onChange={e => onChange(slots.find(el => el.slotId === e.target.value))}
        className="popup__times"
      >
        {Object.keys(memoSlots).map((key: string) => {
          const splitKey = dayjs(key).format('D.MM').split('.');
          return (
            <div key={key} className="popup__times-row">
              <div className="popup__times-label">
                <Typography component="p">{`${splitKey[0]} ${configMonth[splitKey[1]]}`}</Typography>
                <Typography type="description">
                  {dayConfig[dayjs(dayjs(key).toString()).day()]}
                </Typography>
              </div>
              <div className="popup__times-body">
                {memoSlots[key].map(slot => (
                  <RadioButton
                    key={slot?.slotId}
                    value={slot.slotId}
                    className={
                      slot.kind === SlotType.Supervision
                        ? 'popup__times-item--supervision'
                        : 'popup__times-item'
                    }
                  >
                    {dayjs(slot.start).format('HH:mm')}
                  </RadioButton>
                ))}
              </div>
            </div>
          );
        })}
      </Radio.Group>
    </Modal>
  );
};

export default PopupSlots;
