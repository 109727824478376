import * as yup from 'yup';

import { IAnketaForm } from '1_shared/config/interfaces/IAnketaForm';

export const schema = yup.object().shape({
  name: yup.string().required('Полное имя обязательно'),
  phone: yup
    .string()
    .required('Номер телефона обязателен')
    .matches(/^\+7 \d{3} \d{3} \d{2} \d{2}$/, 'Некорректный номер телефона'),
  chooseContactType: yup.string().required('Выберите способ связи'),
  keyThemas: yup.array().of(yup.string()),
  wellBeing: yup.string(),
  time: yup.string(),
  dayType: yup.string().nullable(),
  haveExpWithPsy: yup.string(),
  sex: yup.string().nullable(),
}) as yup.ObjectSchema<IAnketaForm>;
