export enum EContactType {
  Sms = 'SMS',
  Telegram = 'TELEGRAM',
  WhatsApp = 'WHATS_APP',
  CallMe = 'CALL_ME',
}

export const contactTypeMapping: Record<EContactType, string> = {
  [EContactType.Sms]: 'Смс',
  [EContactType.Telegram]: 'Телеграмм',
  [EContactType.WhatsApp]: 'WhatsApp',
  [EContactType.CallMe]: 'Позвоните мне сразу',
};
