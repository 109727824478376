import { Controller, useFormContext } from 'react-hook-form';
import { CloseCircleFilled } from '@ant-design/icons';
import cn from 'classnames';
import dayjs from 'dayjs';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { IOption } from '1_shared/config/interfaces/IOption';
import {
  dayTypeFilterOptions,
  experienceFilterOptions,
  sexFilterOptions,
} from '1_shared/constants/filterEnums';
import { DatePicker, Input, Select } from '1_shared/ui';

import { priceOptions, timeOptions } from '../options/options';

import { IFilterFormProps } from './interfaces/IFilterFormProps';

import styles from './FilterForm.module.scss';

const FiltersForm = ({ handleFieldChange }: IFilterFormProps) => {
  const { control, watch, getValues, setValue } =
    useFormContext<ISpecialistListFilters>();

  watch('moneyFilter.minMoneyRate');

  const { data: workWithOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/work-withs',
    getDictionaries,
  );
  const { data: keyThemesOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/key-themes',
    getDictionaries,
  );
  const { data: specialitiesOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/specialities',
    getDictionaries,
  );

  const handleTimeChange = (value: { start: string; end: string }) => {
    setValue('slotsFilter.localTimeFrom', value.start || '');
    setValue('slotsFilter.localTimeTo', value.end || '');
    handleFieldChange();
  };

  const handlePriceChange = (value: {
    min: number | null;
    max: number | null;
  }) => {
    setValue('moneyFilter.minMoneyRate', value.min);
    setValue('moneyFilter.maxMoneyRate', value.max);
    handleFieldChange();
  };

  const isNotNullOrUndefined = (value: number | null | undefined): boolean =>
    value !== null && value !== undefined;

  return (
    <div className={styles.root}>
      <div className={styles.workWithContainer}>
        <Controller
          name="workWith"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Все темы"
              name="workWith"
              label="с кем работают"
              className={cn(styles.workWith, styles.select, {
                [styles.selected]: value,
              })}
              value={
                value &&
                workWithOptions.find((option: any) => option.value === value)
              }
              options={workWithOptions}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />

        <Controller
          name="keyThemes"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="Ключевые темы"
              name="keyThemes"
              label="ключевые темы"
              optionFilterProp="label"
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              options={keyThemesOptions}
              suffixIcon={
                value &&
                value.length > 0 && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              value={value}
              mode="multiple"
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />

        <Controller
          name="specialties"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Любые"
              name="specialties"
              label="специальность"
              value={value}
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              options={specialitiesOptions}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />
        <div className={styles.priceSexContainer}>
          <Controller
            name="price"
            control={control}
            render={({ field: { value, onChange } }) => {
              const parsedValue = value ? JSON.parse(value) : {};
              const selectedOption = priceOptions.find(
                option =>
                  option.value.min === parsedValue.min &&
                  option.value.max === parsedValue.max,
              );

              return (
                <Select
                  placeholder="Любой"
                  name="price"
                  label="диапазон цен"
                  value={
                    selectedOption ? JSON.stringify(selectedOption.value) : null
                  }
                  options={priceOptions.map(option => ({
                    label: option.label,
                    value: JSON.stringify(option.value),
                  }))}
                  className={cn(styles.workWith, {
                    [styles.selected]: selectedOption,
                  })}
                  onChange={value => {
                    onChange(value);
                    handlePriceChange(JSON.parse(value));
                  }}
                  suffixIcon={
                    isNotNullOrUndefined(parsedValue.min) ||
                    isNotNullOrUndefined(parsedValue.max) ? (
                      <CloseCircleFilled
                        className={styles.icon}
                        onClick={() => {
                          onChange(null);
                          handlePriceChange({ min: null, max: null });
                        }}
                      />
                    ) : null
                  }
                />
              );
            }}
          />

          <Controller
            name="sex"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                placeholder="Не важен"
                name="sex"
                label="пол"
                value={value}
                options={sexFilterOptions}
                className={cn(styles.workWith, {
                  [styles.selected]: value,
                })}
                suffixIcon={
                  value && (
                    <CloseCircleFilled
                      className={styles.icon}
                      onClick={() => {
                        onChange(null);
                        handleFieldChange();
                      }}
                    />
                  )
                }
                onChange={value => {
                  onChange(value || null);
                  handleFieldChange();
                }}
              />
            )}
          />
        </div>
      </div>

      <div className={styles.dateContainer}>
        <div className={styles.experienceDayTypeContainer}>
          <Controller
            name="experience"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder="Не важен"
                name="experience"
                value={value}
                label="опыт"
                options={experienceFilterOptions}
                className={cn(styles.workWith, {
                  [styles.selected]: value,
                })}
                onChange={value => {
                  onChange(value || null);
                  handleFieldChange();
                }}
                suffixIcon={
                  value && (
                    <CloseCircleFilled
                      className={styles.icon}
                      onClick={() => {
                        onChange(null);
                        handleFieldChange();
                      }}
                    />
                  )
                }
              />
            )}
          />

          <Controller
            name="slotsFilter.dayType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder="Не важен"
                name="dayType"
                label="тип дня"
                value={value}
                options={dayTypeFilterOptions}
                className={cn(styles.workWith, {
                  [styles.selected]: value,
                })}
                onChange={value => {
                  onChange(value || null);
                  handleFieldChange();
                }}
                suffixIcon={
                  value && (
                    <CloseCircleFilled
                      className={styles.icon}
                      onClick={() => {
                        onChange(null);
                        handleFieldChange();
                      }}
                    />
                  )
                }
              />
            )}
          />
        </div>
        <div className={styles.dateLocalTimeContainer}>
          <Controller
            name="slotsFilter.date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                name="date"
                showNow={false}
                minDate={dayjs().add(1, 'day')}
                value={value ? dayjs(value) : null}
                label="дата"
                onChange={value => {
                  onChange(value || null);
                  handleFieldChange();
                }}
                onKeyDown={e => {
                  if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                    e.preventDefault();
                  }
                }}
                className={cn(styles.workWith, {
                  [styles.selected]: value,
                })}
              />
            )}
          />

          <Controller
            name="slotsFilter.localTime"
            control={control}
            render={() => {
              const selectedOption = timeOptions.find(
                option =>
                  option.value.start ===
                    getValues('slotsFilter.localTimeFrom') &&
                  option.value.end === getValues('slotsFilter.localTimeTo'),
              );

              return (
                <Select
                  placeholder="Любое время"
                  name="localTime"
                  label="время"
                  value={selectedOption || null}
                  options={timeOptions.map(option => ({
                    label: option.label,
                    value: JSON.stringify(option.value),
                  }))}
                  className={cn(styles.workWith, {
                    [styles.selected]: selectedOption,
                  })}
                  onChange={option => {
                    handleTimeChange(JSON.parse(option));
                  }}
                  suffixIcon={
                    getValues('slotsFilter.localTimeFrom') ||
                    getValues('slotsFilter.localTimeTo') ? (
                      <CloseCircleFilled
                        className={styles.icon}
                        onClick={() => {
                          handleTimeChange({ start: '', end: '' });
                          handleFieldChange();
                        }}
                      />
                    ) : null
                  }
                />
              );
            }}
          />
        </div>
        <Controller
          name="surname"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              placeholder="Иванов"
              type="string"
              name="surname"
              value={value}
              label="Фамилия специалиста"
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              onInput={e => {
                onChange((e.target as HTMLInputElement)?.value || null);
                handleFieldChange();
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default FiltersForm;
