import { ReactElement } from 'react';
import { Image } from 'antd';
import cn from 'classnames';

import DoTherapyLogo from '1_shared/assets/images/doTherapyLogo.png';
import { MediaType } from '1_shared/config/interfaces/MediaType';

import { IPreviewImage } from './interfaces/IPreviewImage';

import styles from './PreviewImage.module.scss';

const PreviewImage = ({
  media,
  dark = false,
  noAvatar = false,
  className = '',
  ...others
}: IPreviewImage): ReactElement => {
  const { mediaType, mainMediaLink, previewLink } = media;

  return (
    <div
      className={cn(styles.root, {
        [className]: className !== '',
        [styles.darkRoot]: dark,
        [styles.noAvatar]: noAvatar,
      })}
    >
      <Image
        {...others}
        className={styles.antImage}
        preview={
          mediaType === MediaType.Video && {
            // eslint-disable-next-line react/no-unstable-nested-components
            imageRender: () => (
              <video muted width="100%" controls src={mainMediaLink} />
            ),
          }
        }
        src={previewLink ?? DoTherapyLogo}
        style={
          !previewLink
            ? { width: 'inherit', borderRadius: '0 !important' }
            : undefined
        }
      />
    </div>
  );
};

export default PreviewImage;
