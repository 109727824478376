import { UploadFile } from 'antd';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { IFirstFormData } from '../module/interfaces/IFirstFormData';
import { IFirstFormSpec } from '../module/interfaces/IFirstFormSpec';
import { IUserInfo } from '../module/interfaces/IUserInfo';

export const userInfoSchema = yup
  .object<IUserInfo>()
  .shape({
    firstName: yup.string().required('Имя обязательно для заполнения'),
    surname: yup.string().required('Фамилия обязательна для заполнения'),
    patronymic: yup.string(),
    sex: yup.string().required('Пол обязателен для заполнения'),
    birthday: yup
      .string()
      .required('Дата рождения обязательна для заполнения')
      .test('is-18-or-older', 'Вам должно быть не менее 18 лет', value => {
        if (!value) return false;
        return dayjs().diff(dayjs(value), 'year') >= 18;
      }),
    email: yup
      .string()
      .email('Некорректный email')
      .required('Почта обязательна для заполнения'),
    phone: yup
      .string()
      .matches(
        /\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/,
        'Номер должен быть в виде +7 999 999 99 99',
      )
      .required('Телефон обязателен для заполнения'),
  })
  .required();

export const schema = yup.object().shape({
  avatar: yup.mixed<UploadFile>(),
  mediaFiles: yup.array().of(yup.mixed<UploadFile>().required()),
  data: yup
    .object<IFirstFormData>()
    .shape({
      userInfo: userInfoSchema,
      startWorkDate: yup.string().required(),
      specialities: yup.array().of(yup.string()).required(),
      workWiths: yup.array().of(yup.string()).required(),
      workMethods: yup.array().of(yup.string()).required(),
      keyThemes: yup.array().of(yup.string()).required(),
      about: yup.string().required('sometext'),
    })
    .required(),
}) as yup.ObjectSchema<IFirstFormSpec>;
