import { EDayTime } from './interfaces/EDayTime';

export const dayTimeConfig: Record<
  EDayTime,
  { localTimeFrom: string; localTimeTo: string }
> = {
  [EDayTime.Any]: {
    localTimeFrom: '',
    localTimeTo: '',
  },
  [EDayTime.Morning]: {
    localTimeFrom: '08:00',
    localTimeTo: '11:59',
  },
  [EDayTime.Day]: {
    localTimeFrom: '12:00',
    localTimeTo: '15:59',
  },
  [EDayTime.Evening]: {
    localTimeFrom: '16:00',
    localTimeTo: '23:59',
  },
};
