import { RoutePath } from '../../../1_shared/config/routes';

import { IConfHeadersLink } from './interfaces/IConfHeadersLink';

export const headerLinks: IConfHeadersLink[] = [
  {
    label: 'Специалисты',
    url: RoutePath.SPECIALISTS,
    isAccordion: false,
  },
  {
    label: 'Компаниям',
    url: 'https://old.dotherapy.ru/b2b',
    isAccordion: false,
  },
  {
    label: '15 мин консультация',
    url: '#application',
    isAccordion: false,
  },
  {
    label: 'О нас',
    url: '/#about',
    isAccordion: false,
  },
  {
    label: 'Блог',
    url: 'https://blog.dotherapy.ru/',
    isAccordion: false,
  },
];

export const headerLinksUZ: IConfHeadersLink[] = [
  {
    label: 'Специалисты',
    url: RoutePath.SPECIALISTS,
    isAccordion: false,
  },
  {
    label: 'Персональная консультация',
    url: '#application',
    isAccordion: false,
  },
  {
    label: 'О нас',
    url: '/#about',
    isAccordion: false,
  },
];
