import { useLocation, useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { Roles } from '1_shared/config/interfaces/Roles';
import { SpecStatus } from '1_shared/config/interfaces/SpecStatus';
import { RoutePath } from '1_shared/config/routes';

import { profileSpec } from './api/auth.service';

const useSpecDetect = (): ((role: Roles) => Promise<void>) => {
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, trigger: checkProfile } = useSWRMutation(
    '/spec/specialist/profile',
    profileSpec,
    {
      onSuccess: data => {
        if (data.status === SpecStatus.NotActive) {
          navigate(RoutePath.FIRST_FORM);
        }
        if (
          data.status !== SpecStatus.NotActive &&
          location.pathname === RoutePath.FIRST_FORM
        ) {
          navigate(RoutePath.MAIN);
        }
      },
    },
  );

  const specDetect = async (role: Roles) => {
    if (role === Roles.Spec) {
      await checkProfile();
    }
  };

  return specDetect;
};

export default useSpecDetect;
