import DoTherapyLogo from '1_shared/assets/images/doTherapyLogo.png';
import ProfileUser from '1_shared/assets/images/UserProfile.svg';
import { IMediaContentById } from '1_shared/config/interfaces/IMediaContentById';
import { MediaType } from '1_shared/config/interfaces/MediaType';

export const configMedia: IMediaContentById = {
  id: '1',
  isDefault: true,
  mediaContentResponse: {
    isDefault: true,
    mediaType: MediaType.Image,
    mainMediaLink: DoTherapyLogo,
    previewLink: DoTherapyLogo,
  },
};

export const configMediaClient: IMediaContentById = {
  id: '1',
  isDefault: true,
  mediaContentResponse: {
    isDefault: true,
    mediaType: MediaType.Image,
    mainMediaLink: ProfileUser,
    previewLink: ProfileUser,
  },
};
