import { useState } from 'react';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { sortBy } from 'lodash';

import ProfileUser from '1_shared/assets/images/UserProfile.svg';
import ProfileUserPast from '1_shared/assets/images/UserProfilePast.svg';
import { Button, CheckboxCircle, Typography } from '1_shared/ui';

import { ReminderType } from '../../../1_shared/config/interfaces/RemainderType';
import { Roles } from '../../../1_shared/config/interfaces/Roles';
import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { SpecialistAvatar } from '../../../2_entities';
import { configMedia } from '../../../4_widgets/SpecialistCard/ui/config/configMedia';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import ModalFeedback from '../../ModalFeedback/ui/ModalFeedback';
import { ProfileSession } from '../../SessionsPc/api/interfaces/ProfileSession';
import { ETypeTable } from '../config/interfaces/ETypeTable';
import useActionsSession from '../module/useActionsSession';

import { IPropsSessionTable } from './interfaces/IPropsSessionTable';

import styles from './SessionTable.module.scss';

const SessionTable = ({
  typeTable = ETypeTable.Future,
  items,
  mutate,
}: IPropsSessionTable) => {
  const { user, role } = useAuthContext();
  const { reminderSwitchAction, cancelSessionAction } =
    useActionsSession(mutate);
  const [open, setOpen] = useState<{
    openModal: boolean;
    session: ProfileSession | null;
  }>({
    openModal: false,
    session: null,
  });
  const isSpecialist = role === Roles.Spec;

  const openModal = (session: ProfileSession) => {
    setOpen({ session, openModal: true });
  };

  const onClose = () => {
    setOpen(prev => ({ ...prev, openModal: false }));
  };

  const getSessionCalendarEvent = (session: ProfileSession) => {
    const url = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//hacksw/handcal//NONSGML v1.0//EN',
      'BEGIN:VEVENT',
      'SUMMARY:Запись на прием',
      'UID:19970610T172345Z-AF23B2@example.com',
      `DTSTART:${dayjs(session.start).format('YYYYMMDDTHHmmss')}`,
      `DTEND:${dayjs(session.end).format('YYYYMMDDTHHmmss')}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ];

    const blob = new Blob([url.join('\n')], {
      type: 'text/calendar;charset=utf-8',
    });

    saveAs(blob, 'Запись на прием.ics');
  };

  const clientPreview =
    typeTable === ETypeTable.Future ? ProfileUser : ProfileUserPast;

  return (
    <div className={styles.root}>
      <table className={styles.tableCont}>
        <thead>
          <tr className={styles.columnsTitle}>
            <th align="left">
              <Typography type="subtitle">
                {isSpecialist ? 'КЛИЕНТ' : 'СПЕЦИАЛИСТ'}
              </Typography>
            </th>
            <th align="left">
              <Typography type="subtitle">ДАТА И ВРЕМЯ</Typography>
            </th>
            {user?.email && !isSpecialist && (
              <th align="left">
                <Typography type="subtitle">EMAIL</Typography>
              </th>
            )}
            {user?.phone && !isSpecialist && (
              <th align="left">
                <Typography type="subtitle">ТЕЛЕФОН</Typography>
              </th>
            )}
            {!isSpecialist && (
              <th align="left">
                <Typography type="subtitle">СТОИМОСТЬ</Typography>
              </th>
            )}
            {!isSpecialist && (
              <th align="left">
                <Typography type="subtitle">ВАШЕ ИМЯ (ПСЕВДОНИМ)</Typography>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortBy(items, 'start')
            .reverse()
            .map(session => (
              <tr key={session.id} className={styles.item}>
                <td>
                  <SpecialistAvatar
                    firstName={
                      !isSpecialist
                        ? session.specialist.firstName
                        : session.clientNickname
                    }
                    lastName={!isSpecialist ? session.specialist.firstName : ''}
                    patronymic={
                      !isSpecialist ? session.specialist.patronymic : ''
                    }
                    imgPreview={
                      isSpecialist
                        ? clientPreview
                        : session?.specialist?.avatar ||
                          configMedia.mediaContentResponse.previewLink
                    }
                  />
                </td>
                <td>
                  <div className={styles.subItem}>
                    <Typography>
                      {dayjs(session.start, 'YYYY-MM-DDThh:mm:ss').format(
                        'DD MMMM, HH:mm',
                      )}
                    </Typography>
                    {typeTable === ETypeTable.Future && !isSpecialist && (
                      <button
                        className={styles.btnText}
                        type="button"
                        onClick={() => getSessionCalendarEvent(session)}
                      >
                        <div className={styles.calendar} />

                        <Typography
                          type="description"
                          className={styles.btnTextDescr}
                        >
                          Добавить в календарь
                        </Typography>
                      </button>
                    )}
                  </div>
                </td>
                {user?.email && !isSpecialist && (
                  <td>
                    <div className={styles.subItem}>
                      <Typography className={styles.longText}>
                        {user.email}
                      </Typography>
                      {typeTable === ETypeTable.Future && (
                        <CheckboxCircle
                          onChange={() =>
                            reminderSwitchAction(session.id, ReminderType.Email)
                          }
                          checked={
                            !!session.reminders.find(
                              reminder => reminder.type === ReminderType.Email,
                            )
                          }
                        >
                          Оповещать
                        </CheckboxCircle>
                      )}
                    </div>
                  </td>
                )}
                {user?.phone && !isSpecialist && (
                  <td>
                    <div className={styles.subItem}>
                      <Typography>{phoneConvert(user?.phone)}</Typography>
                      {typeTable === ETypeTable.Future && (
                        <CheckboxCircle
                          onChange={() =>
                            reminderSwitchAction(session.id, ReminderType.Phone)
                          }
                          checked={
                            !!session.reminders.find(
                              reminder => reminder.type === ReminderType.Phone,
                            )
                          }
                        >
                          Оповещать
                        </CheckboxCircle>
                      )}
                    </div>
                  </td>
                )}
                {!isSpecialist && (
                  <td>
                    <Typography>{`${session?.price || 0} ₽`}</Typography>
                  </td>
                )}
                {!isSpecialist && (
                  <td>
                    <div className={styles.subItem}>
                      <Typography>{user?.nickname}</Typography>
                    </div>
                  </td>
                )}
                <td align="right">
                  {typeTable === ETypeTable.Future && !isSpecialist && (
                    <Button
                      onClick={() => cancelSessionAction(session.id)}
                      danger
                      className={styles.btnDanger}
                    >
                      ОТМЕНИТЬ СЕАНС
                    </Button>
                  )}
                  {isSpecialist &&
                    typeTable === ETypeTable.Past &&
                    dayjs(items?.[0].start) < dayjs() && (
                      <Button
                        type="secondary"
                        onClick={() => openModal(session)}
                      >
                        ОСТАВИТЬ ОБРАТНУЮ СВЯЗЬ
                      </Button>
                    )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {open.session && (
        <ModalFeedback
          open={open.openModal}
          onClose={onClose}
          session={open.session}
        />
      )}
    </div>
  );
};

export default SessionTable;
